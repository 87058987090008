import React from 'react'
import Layout from '../components/layout'






const LoginIndex = (props) => {

    
    


    return (
      <Layout location={props.location}>
        <div className='empty_box'></div>
        <iframe title='login' frameborder="0" height="770px" scrolling="yes" src="https://manegeplan.azurewebsites.net/index.php?id=81856075" width="100%"></iframe>
      </Layout>

    )
  }


export default LoginIndex
